.app-body {
    max-width: 1100px;
    margin: auto;
    background-color: #FAFAFB;
    padding-bottom: 3em;
}

.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before, .s-alert-close::after {
    width: 2px;
}
